<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">推荐课程主题</a>
          <i>></i>
          <a href="javascript:;">推荐课程列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem">
              <span class="itemLabel">主题名称:</span>
              <el-input
                v-model="searchData.themeName"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入主题名称"
                clearable
              />
            </div>
            <div class="searchboxItem ci-full">
              <span class="itemLabel">活动状态:</span>
              <el-select
                v-model="searchData.themeState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in themeStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <el-button class="bgc-bv" type="primary" @click="getData()"
                >查询</el-button
              >
              <el-button
                class="bgc-bv"
                type="primary"
                @click="EditDialog('add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :header-cell-style="tableHeader"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="主题名称"
                align="center"
                prop="themeName"
                :show-overflow-tooltip="true"
              />

              <el-table-column
                label="浏览人数"
                align="center"
                prop="browseNum"
              />
              <el-table-column label="购买人数" align="center" prop="payNum" />
              <el-table-column label="封面" align="center" prop="themeCoverUrl">
                <template slot-scope="scope">
                  <el-image
                    style="width: 30px; height: 30px; border-radius: 50%"
                    :src="scope.row.themeCoverUrl"
                    :preview-src-list="[scope.row.themeCoverUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" prop="themeState">
                <template slot-scope="scope">
                  {{ scope.row.themeState == "10" ? "下架" : "上架" }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建日期"
                align="center"
                prop="createTime"
                width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="280px">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="EditDialog('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    :disabled="scope.row.themeState == '20'"
                    @click="certificateRelease(scope.row.themeId)"
                    >上架</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    :disabled="scope.row.themeState == '10'"
                    @click="certificateStopEnrollment(scope.row.themeId)"
                    >下架</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @getData="getData"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <el-dialog
      :title="DialogTitle"
      :visible.sync="DialogVisible"
      width="50%"
      center
      @close="DialogCancel"
    >
      <el-form
        ref="DialogFromRef"
        :model="DialogFrom"
        :rules="DialogFromRules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="推荐主题：" prop="themeName">
          <el-input v-model="DialogFrom.themeName"></el-input>
        </el-form-item>
        <el-form-item label="主题封面：" prop="themeCoverUrl">
          <el-upload
            :on-change="handlebusinessLicense"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="DialogFrom.themeCoverUrl || require('@/assets/develop.png')"
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>
        <el-form-item label="跳转路径：" prop="themeContent">
          <el-input v-model="DialogFrom.themeContent"></el-input>
        </el-form-item>
        <el-form-item label="课程：">
          <el-button @click="SelectCourse()">选择课程</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="tableCourseData"
        :header-cell-style="tableHeader"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="序号" align="center" type="index" />
        <el-table-column
          label="课程名称"
          align="center"
          prop="courseName"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="课程分类"
          align="center"
          prop="courseTypeName"
        />
        <el-table-column label="课节" align="center" prop="coursewareNum" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="deleteCourse(scope.row, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogCancel">取 消</el-button>
        <el-button type="primary" @click="DialogSure()">确 定</el-button>
      </span>
    </el-dialog>
    <Course ref="Course" @eventBus="courseTableMoreBack" />
  </div>
</template>

<script>
import List from "@/mixins/List";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import Course from "@/views/miniApp/recommendCourses/popUp/courseMini.vue";
export default {
  components: {
    Empty,
    PageNum,
    Course,
  },
  mixins: [List],
  data: function () {
    return {
      /* 搜索条件 */
      searchData: {
        themeName: "", //主题名称
        themeState: "", //活动状态
      },
      /* 活动状态下拉数据  */
      themeStateList: [
        {
          value: "20",
          label: "已上架",
        },
        {
          value: "10",
          label: "已下架",
        },
      ],
      DialogTitle: "新增",
      DialogVisible: false,
      /* 弹窗数据 */
      DialogFrom: {
        themeName: "", //活动名称
        themeCoverUrl: "", //主题封面Url
        themeCover: "", //主题封面Key
        themeContent: "", //跳转路径
      },
      /* 弹窗数据 -- 校验*/
      DialogFromRules: {
        themeName: [
          { required: true, message: "请输入主题名称", trigger: "blur" },
        ],
        themeCoverUrl: [
          { required: true, message: "请上传主题封面", trigger: "change" },
        ],
      },
      /* 选中课程数据 */
      tableCourseData: [],
      /* 缓存数据 */
      tableCacheData: [],
      /* 删除选中课程有关联relationId的courseId */
      courseDeleteList: [],
    };
  },
  mounted() {},
  created() {},
  methods: {
    /* 主题封面 */
    handlebusinessLicense(res) {
      this.uploadFetch(res.raw);
    },
    uploadFetch(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.themeCoverUrl = ret.data.fileURL || "";
        that.DialogFrom.themeCover = ret.data.fileKey || "";
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.themeName) {
        params.themeName = this.searchData.themeName;
      }
      if (this.searchData.themeState) {
        params.themeState = this.searchData.themeState;
      }
      this.doFetch(
        {
          url: "/minicourse/theme/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    /* 新增||编辑 */
    EditDialog(stu, row) {
      this.DialogVisible = true;
      if (stu == "edit") {
        this.DialogTitle = "编辑活动";
        this.getInfo(row.themeId);
      } else {
        this.DialogTitle = "新增活动";
      }
    },
    getInfo(themeId) {
      this.$post(
        "/minicourse/theme/getInfo",
        {
          themeId,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          this.DialogFrom = {
            ...this.DialogFrom,
            themeName: ret.data.themeName,
            themeCover: ret.data.themeCover,
            themeCoverUrl: ret.data.themeCoverUrl,
            themeContent: ret.data.themeContent,
            themeId: ret.data.themeId,
          };
          ret.data.courseList.map((el) => {
            this.tableCourseData.push({
              courseName: el.courseName,
              courseId: el.courseId,
              courseTypeName: el.courseTypeName,
              coursewareNum: el.coursewareNum,
              relationId: el.relationId,
            });
            this.tableCacheData.push({
              courseName: el.courseName,
              courseId: el.courseId,
              courseTypeName: el.courseTypeName,
              coursewareNum: el.coursewareNum,
              relationId: el.relationId,
            });
          });
        })
        .catch((err) => {
          return;
        });
    },
    // 选择课程
    SelectCourse() {
      this.$refs.Course.showPopUp(this.tableCourseData);
    },
    courseTableMoreBack(ret) {
      this.tableCourseData = [];
    //   for (let i = 0; i < this.tableCourseData.length; i++) {
    //           if (!this.cousePushcheck(this.tableCourseData[i].courseId)) {
    //             this.courseDeleteList.push({
    //               courseId: this.tableCourseData[i].courseId,
    //             });
    //           }
    //         }
      ret.map((el) => {
        if (this.courseDeleteList) {
          this.courseDeleteList.map((el1, index) => {
            if (el.courseId == el1) {
              this.courseDeleteList.splice(index, 1);
            }
          });
        }
        
        // this.tableCacheData
        this.tableCourseData.push({
          courseName: el.courseName,
          courseId: el.courseId,
          courseTypeName: el.courseTypeName,
          coursewareNum: el.coursewareNum,
          relationId: el.relationId,
        });
      });
      console.log(this.tableCourseData);
    },
    deleteCourse(row, index) {
      this.tableCourseData.splice(index, 1);
      if (row.relationId) {
        this.courseDeleteList.push(row.courseId);
        console.log(this.courseDeleteList);
      }
    },
    //新增确认
    DialogSure() {
      this.$refs.DialogFromRef.validate((valid) => {
        if (valid) {
          if (!this.tableCourseData.length) {
            this.$message.error("请选择推荐活动课程");
            return false;
          }
          let courseList = [],
            courseDeteleList = [];
          this.courseDeleteList.map((el) => {
            courseDeteleList.push({
              courseId: el,
            });
          });
          //缓存数据
          if (this.tableCacheData) {
            for (let i = 0; i < this.tableCourseData.length; i++) {
              if (!this.cousePushcheck(this.tableCourseData[i].courseId)) {
                courseList.push({
                  courseId: this.tableCourseData[i].courseId,
                });
              }
            }
          } else {
              this.tableCourseData.map(el =>{
                   courseList.push({
                  courseId: el.courseId,
                });
              })
          }

          //   this.tableCourseData.filter((item) => item.courseId);
          let data = {
            themeName: this.DialogFrom.themeName, // 活动主题
            courseList,
            courseDeteleList,
          };
          if (this.DialogFrom.themeCover) {
            data.themeCover = this.DialogFrom.themeCover;
          }
          if (this.DialogFrom.themeContent) {
            data.themeContent = this.DialogFrom.themeContent;
          }
          if (this.DialogFrom.themeId) {
            data.themeId = this.DialogFrom.themeId;
          }
          console.log(data);
          this.$post(
            this.DialogFrom.themeId
              ? "/minicourse/theme/modify"
              : "/minicourse/theme/insert",
            data,
            3000,
            true,
            2
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message.success("保存成功");
                this.empty();
                this.DialogVisible = false;
                this.getData(-1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    cousePushcheck(courseId) {
      for (let n = 0; n < this.tableCacheData.length; n++) {
        if (courseId == this.tableCacheData[n].courseId) {
          return true;
        }
      }
      return false;
    },
    //新增取消
    DialogCancel() {
      this.empty();
      this.DialogVisible = false;
    },
    empty() {
      this.DialogFrom = {
        themeName: "",
        themeCover: "",
        themeCoverUrl: "",
        themeState: "",
      };
      this.tableCourseData = [];
      this.tableCacheData = [];
      this.courseDeleteList = [];
      this.$refs.DialogFromRef.resetFields();
    },
    //发布
    certificateRelease(themeId) {
      this.$post(
        "/minicourse/theme/updateState",
        { themeId },
        3000,
        true,
        2
      ).then((ret) => {
        if (ret.status == "0") {
          this.$message.success("发布成功");
          this.getData(-1);
        }
      });
    },
    //终止发布
    certificateStopEnrollment(themeId) {
      this.$post(
        "/minicourse/theme/updateState",
        { themeId },
        3000,
        true,
        2
      ).then((ret) => {
        if (ret.status == "0") {
          this.$message.success("已停止");
          this.getData(-1);
        }
      });
    },
  },
};
</script>

<style>
</style>